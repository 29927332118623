import React from 'react';
import '../styles.scss';
import PropTypes from 'prop-types';
import Screen from '../components/Screen';
import GradualNumber from '../../components/GradualNumber';

export default function TeamPlayersScreen({teams, styles = {}}) {
	
	return <Screen
		className={`scores has-scores`}
		bgColor={styles.bgColor}
		mediaBg={styles.background}
	>
		<div className={`screen-content`}>
			<div className={'teams-container'}>
				{teams.map((team, i) => {
					return <div className={'team-card'} style={{backgroundColor: team.color}} key={`team${team._id}`}>
						<div className={'team-name'}>{team.name}</div>
						<div className={'team-card__img'} style={team.img ? {backgroundImage: `url("${team.img.src}")`} : null}></div>
						<div className={'team-card__content'}>
							<div className={'total-users'}>
								<GradualNumber value={team.stats.users}/>
							</div>
						</div>
					</div>
				})}
			</div>
		</div>
	</Screen>
}

TeamPlayersScreen.propTypes = {
	teams: PropTypes.array.isRequired,
	styles: PropTypes.object
};
