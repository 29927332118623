import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import Message from '../components/Message';
import './styles.scss';
import LeaderboardBlock from './LeaderboardBlock';

function LeaderboardPage() {
	console.log('Render LeaderboardPage')
	
	const {quizId} = useParams();
	const [state, setState] = useState({
		quiz: null,
		message: null
	});
	
	useEffect(() => {
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/${quizId}/leaderboard?limit=10`;
				const response = await fetch(url);
				const quiz = await response.json();
				
				if (quiz.error) {
					setState({message: quiz.error});
				} else {
					setState({
						message: null,
						quiz
					});
				}
			} catch (e) {
				console.log(e);
				setState({message: 'Something went wrong'});
			}
		})();
	}, [quizId]);
	
	return (
		<div className={`main-page`}>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{state.quiz && <LeaderboardBlock quiz={state.quiz}/>}
		</div>
	)
}

export default LeaderboardPage;
