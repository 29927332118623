import React, {useEffect, useState} from 'react';
import ScoresScreen from './ScoresScreen';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';

const objectPath = require('object-path');

export default function SidebarBlock(props) {
	console.log('Render SidebarBlock');
	
	const quiz = props.quiz;
	const cssClass = props.cssClass;
	const showPos = props.showPos;
	const showTeam = props.showTeam;
	const socket = props.socket;
	const round = props.round;
	
	const [state, setState] = useState({
		quiz,
		round,
		screen: null,
		questionScores: null,
		globalScores: null,
		scoresType: 'global',
		allowScroll: true,
		scrollDelay: 5000,
		timeout: null,
		globalScoresScrollDelay: props.globalScoresScrollDelay,
		resultsTime: props.resultsTime,
		resultsScrollDelay: props.resultsScrollDelay
	});
	
	const onCmd = cmd => {
		
		if (cmd.screen === 'poll-result') {
			return;
		}
		
		setState(prev => {
				let screen = cmd.screen;
				// Enable for polls only
				// if (prev.round === 'round1' && screen === 'scores') {
				// 	screen = 'question';
				// } else if (screen === 'poll-question') {
				// 	screen = 'scores';
				// }
				
				const newState = {
					...prev,
					screen,
					complete: cmd.complete
				};
				if (cmd.results) {
					newState.questionScores = populateTeams(cmd.results, prev.quiz.teams);
				}
				if (cmd.globalResults) {
					newState.globalScores = populateTeams(cmd.globalResults, prev.quiz.teams);
				}
				
				if (screen === 'scores') {
					const resultsEndTime = cmd.screenDate + prev.resultsTime * 1000;
					const dt = resultsEndTime - Date.now();
					
					if (dt > 0) {
						newState.scoresType = 'question';
						newState.allowScroll = true;
						newState.scrollDelay = prev.resultsScrollDelay;
						
						clearTimeout(newState.timeout);
						newState.timeout = setTimeout(() => {
							setState(prev => {
								return {
									...prev,
									scoresType: 'global',
									allowScroll: false
								}
							})
						}, dt);
					} else {
						newState.scoresType = 'global';
						newState.allowScroll = false;
					}
				} else {
					newState.scoresType = 'global';
					newState.allowScroll = true;
					newState.scrollDelay = prev.globalScoresScrollDelay;
				}
				
				return newState;
			}
		);
	}
	
	const onLeaderboardChange = data => {
		setState(prev => {
			if (state.screen !== 'answer') {
				return {
					...prev,
					globalScores: populateTeams(data.users, prev.quiz.teams)
				};
			} else {
				return prev;
			}
		});
	}
	
	useEffect(() => {
		try {
			const scores = populateTeams(objectPath.get(quiz, 'leaderboard.topUsers'), quiz.teams);
			setState(prev => {
				return {
					...prev,
					quiz,
					globalScores: scores
				};
			});
			
			socket.on('cmd', onCmd);
			socket.on('leaderboard:change', onLeaderboardChange);
			
		} catch (e) {
			console.log(e);
		}
		
		return () => {
			if (socket) {
				socket.off('cmd', onCmd);
				socket.off('leaderboard:change', onLeaderboardChange);
			}
			
			if (state && state.timeout) {
				clearTimeout(state.timeout);
			}
		}
	}, []);
	
	const scores = state.scoresType === 'question' ? state.questionScores : state.globalScores;
	const title = <><span className={'title-left'}>{state.scoresType === 'question' ? 'LAST QUESTION' : 'TOP SCORERS'}</span><span className={'title-right'}>Team</span></>
	return (
		<div className={`sidebar ${cssClass}`}>
			<ScoresScreen
				key={state.scoresType}
				autoScroll={state.allowScroll}
				scores={scores || []}
				teams={quiz.teams || []}
				title={title}
				showPos={showPos}
				showTeam={showTeam}
				scrollDelay={state.scrollDelay}
			/>
		</div>
	)
}
