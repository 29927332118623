import React from 'react';
import PropTypes from 'prop-types';
import Screen from '../common-libs/quiz/components/Screen';

export default function Poster(props) {
	
	const quiz = props.quiz;
	let bgColor = '#000';
	let background = quiz.poster;
	
	if (quiz.screens.welcome.enabled) {
		bgColor = props.quiz.screens.welcome.bgColor;
	}
	
	return <Screen mediaBg={{mediaType: 'image', image: background}} bgColor={bgColor}/>
}

Poster.propTypes = {
	quiz: PropTypes.object.isRequired
}