import {useEffect} from 'react';
import PropTypes from 'prop-types';

export default function WithSound({sound, loop, ...props}) {
	
	useEffect(() => {
		
		if (!sound) {
			return;
		}
		
		const audio = new Audio(sound);
		
		const playSound = () => {
			if (audio) {
				audio.loop = !!loop;
				audio
					.play()
					.catch(e => console.log(e));
			}
		}
		
		const handleClick = () => {
			if (audio && audio.paused) {
				playSound();
			}
		};
		
		document.addEventListener('mousedown', handleClick);
		playSound();
		
		return () => {
			document.removeEventListener('mousedown', handleClick);
			if (audio) {
				try {
					audio.pause();
					audio.src = '';
					audio.load();
				} catch (e) {
					console.log(e);
				}
			}
		}
	}, [sound, loop]);
	
	return props.children || '';
}

WithSound.propTypes = {
	sound: PropTypes.string,
	loop: PropTypes.bool
}
