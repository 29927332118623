import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import Screen from '../common-libs/quiz/components/Screen';

import '../common-libs/quiz/styles.scss'

/** @jsxImportSource @emotion/react */

export default function ScoresScreen(
	{
		autoScroll = true,
		scrollDelay = 5,
		changeDirectionDelay = 3,
		showPos = false,
		showTeam = false,
		showNewScores = true,
		title,
		scores
	}
) {
	console.log('Render ScoresScreen', scores?.length);
	const ref = useRef(null);
	
	useEffect(() => {
		let timeout;
		let active = true;
		
		if (!autoScroll) {
			if (ref && ref.current) {
				ref.current.scrollTop = 0;
			}
			
		} else {
			let direction = 'down';
			const step = 0.5;
			const delay = scrollDelay * 1000;
			const cdDelay = typeof changeDirectionDelay === 'undefined' ? delay : changeDirectionDelay * 1000;
			let currentScroll = 0;
			
			const scroll = () => {
				if (!active) {
					console.log('Destroyed scrolling');
					return;
				}
				
				const el = ref && ref.current;
				if (el) {
					if (direction === 'down') {
						currentScroll += step;
						el.scrollTop = currentScroll;
						if (el.scrollHeight - currentScroll <= el.clientHeight) {
							direction = 'up';
							return setTimeout(scroll, cdDelay);
						}
					} else {
						currentScroll -= step;
						el.scrollTop = currentScroll;
						if (currentScroll <= 0) {
							direction = 'down';
							return setTimeout(scroll, cdDelay);
						}
					}
				}
				window.requestAnimationFrame(scroll);
			}
			
			timeout = setTimeout(scroll, delay);
		}
		
		return () => {
			clearTimeout(timeout);
			active = false;
		}
	}, [autoScroll, scrollDelay, changeDirectionDelay]);
	
	// Calculate column sizes.
	let maxScore = 0;
	let maxTotalScore = 0;
	let maxTeamLength = 0;
	
	scores.forEach(row => {
		maxScore = Math.max(maxScore, Math.abs(row.scores));
		maxTotalScore = Math.max(maxTotalScore, Math.abs(row.totalScores));
		
		const team = row.team;
		if (team?._id) {
			maxTeamLength = Math.max(team.name.length, maxTeamLength);
		}
	});
	
	return <Screen
		className={`scores has-scores`}
		css={{
			'.row': {
				'& .position': {
					width: `${scores.length.toString().length}ch`
				},
				'& .user-team': {
					'--team-name-length': maxTeamLength,
				},
				'& .scores': {
					width: `${maxScore.toString().length + 1}ch`
				},
				'& .total-scores': {
					width: `${maxTotalScore.toString().length}ch`
				}
			}
		}}
	>
		<div className={`screen-title`}>{title}</div>
		<div className={`screen-content`}>
			{scores.length > 0 && (
				<div className={`rows`} ref={ref}>
					{scores.map((s, i) => (
						<div key={`si${s.user.channelId}`} className={`row ${s.team ? s.team._id : ''}`}>
							{showPos
								? <div className={'position'}>{showPos ? (i + 1) : ''}</div>
								: <div></div>
							}
							<div className={'user-img'}><img src={s.user.profileImageUrl} alt={'profile pic'}/></div>
							<div className={`user-name`}>{s.user.displayName}</div>
							
							<div className={'total-scores'}>{s.totalScores}</div>
							
							{showNewScores
								? <div className={`scores ${s.scores < 0 ? 'penalty' : ''}`}>{s.scores > 0 ? '+' : ''}{s.scores}</div>
								: <div></div>
							}
							
							{(showTeam && s.team)
								? <div className={'user-team'} style={{
									'--team-color': s.team.color
								}}>{s.team.name}</div>
								: <div className={'user-team'}></div>
							}
						</div>
					))}
				</div>
			)}
		</div>
	</Screen>
}

ScoresScreen.propTypes = {
	autoScroll: PropTypes.bool,
	changeDirectionDelay: PropTypes.number,
	scores: PropTypes.array.isRequired,
	scrollDelay: PropTypes.number,
	showNewScores: PropTypes.bool,
	showPos: PropTypes.bool,
	showTeam: PropTypes.bool,
	styles: PropTypes.object,
	title: PropTypes.any,
};
