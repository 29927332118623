import PropTypes from 'prop-types';
import Counter from './Counter';
import {useEffect, useState} from 'react';

export default function GradualNumber({className = '', value = 0}) {
	
	const [num, setNum] = useState(value || 0);
	
	useEffect(() => {
		const int = setInterval(() => {
			setNum(prev => {
				let final = +value;
				const diff = Math.abs(final - prev);
				if (diff !== 0) {
					let step = Math.max(Math.round(diff / 10), 1);
					let newNumber = diff < 0 ? prev - step : prev + step;
					
					if ((diff < 0 && newNumber < final) || (diff > 0 && newNumber > final)) {
						return final;
					}
					return newNumber;
				}
				return prev;
			})
		}, 50);
		
		return () => clearInterval(int);
	}, [value]);
	
	return <div className={className}>{num.toLocaleString('en-US', {maximumFractionDigits: 0})}</div>
}

Counter.propTypes = {
	className: PropTypes.string,
	value: PropTypes.number
}
