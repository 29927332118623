function populateTeams(scores, teams) {
	if (!scores || !scores.length) {
		return [];
	} else if (!teams || !teams.length) {
		return scores;
	}
	
	scores.forEach(row => {
		if (row.team) {
			row.team = teams.find(team => team._id === row.team);
		}
	});
	
	return scores;
}

module.exports.populateTeams = populateTeams;