import React from 'react';
import QuestionScreen from '../common-libs/quiz/screens/QuestionScreen';
import WithSound from '../common-libs/components/WithSound';
import PropTypes from 'prop-types';

export default function QuestionScreenWithSound(props) {
	const quiz = props.quiz;
	const sound = quiz.screens.question.countdown.sound;
	let soundUrl = null;
	if (sound && sound.length) {
		soundUrl = `${process.env.REACT_APP_S3_BUCKET}/sounds/${sound}.mp3`;
	}
	
	return (
		<WithSound sound={soundUrl} loop={true}>
			<QuestionScreen
				quiz={quiz}
				timeLeft={props.timeLeft}
				question={props.question}
			/>
		</WithSound>
	)
}

QuestionScreenWithSound.propTypes = {
	quiz: PropTypes.object.isRequired,
	timeLeft: PropTypes.number.isRequired,
	question: PropTypes.object.isRequired
}