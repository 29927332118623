import React, {useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import LeaderboardBlock from "./LeaderboardBlock";

function FinalLeaderboardPage() {
	
	const [query] = useSearchParams();
	const [quiz, setQuiz] = useState(null);
	
	/**
	 * Load the scores.
	 */
	useEffect(() => {
		let active = true;
		(async () => {
			try {
				const url = new URL(`${process.env.REACT_APP_API_SERVER}/leaderboard`);
				if (query.get('team')) {
					url.searchParams.append('team', query.get('team'));
				}
				url.searchParams.append('limit', query.get('limit') || 10);
				
				const response = await fetch(url);
				const data = await response.json();
				
				if (!active) {
					return;
				}
				
				setQuiz({
					teams: data.teams,
					leaderboard: {
						topUsers: data.players
					}
				});
				
			} catch (e) {
				console.log(e);
			}
		})();
		
		return () => {
			active = false;
		}
		
	}, []);
	
	return <div className={`main-page`}>
		{quiz && <LeaderboardBlock quiz={quiz}/>}
	</div>
}

export default FinalLeaderboardPage;
