import React from 'react';
import WithSound from '../common-libs/components/WithSound';
import PropTypes from 'prop-types';
import ScoresScreen from '../common-libs/quiz/screens/ScoresScreen';
import FailScreen from '../common-libs/quiz/screens/FailScreen';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';

export default function ScoresScreenWithSound(props) {
	
	const quiz = props.quiz;
	const screen = quiz.screens.scores;
	const scores = populateTeams(props.scores, quiz.teams);
	const hasPositiveScores = scores.length && scores[0].scores > 0;
	const sound = hasPositiveScores ? quiz.screens.scores.success.sound : quiz.screens.scores.fail.sound;
	
	let soundUrl = null;
	if (sound && sound.length) {
		soundUrl = `${process.env.REACT_APP_S3_BUCKET}/sounds/${sound}.mp3`;
	}
	
	return (
		<WithSound sound={soundUrl}>
			{scores.length ?
				<ScoresScreen
					autoScroll={screen.success.autoScroll}
					scores={scores}
					title={screen.title}
					showPos={screen.success.showPos}
					showTeam={screen.success.showTeam}
					scrollDelay={screen.success.scrollDelay}
					styles={{
						title: screen.screenTitle,
						page: screen.success
					}}
				/> :
				<FailScreen model={quiz}/>
			}
		</WithSound>
	)
}

ScoresScreenWithSound.propTypes = {
	quiz: PropTypes.object.isRequired,
	scores: PropTypes.array.isRequired
}