import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import GamePage from './pages/GamePage';
import LeaderboardPage from './pages/LeaderboardPage';
import LeaderboardTeamPage from './pages/LeaderboardTeamPage';
import RoundsLeaderboardPage from './pages/RoundsLeaderboardPage';
import TeamPlayersPage from './pages/TeamPlayersPage';
import Message from './components/Message';

import './common-libs/style.scss';
import 'animate.css'
import './App.css';

import RedBullGamePage from './redbull/GamePage';
import RedBullLeaderboardPage from './redbull/LeaderboardPage';
import RedBullFinalLeaderboardPage from './redbull/FinalLeaderboardPage';
import './redbull/styles.scss';

function App() {
	return (
		<>
			<BrowserRouter>
				<Routes>
					<Route path={'/:quizId'} element={<GamePage/>}/>
					<Route path={'/:quizId/leaderboard'} element={<LeaderboardPage/>}/>
					<Route path={'/:quizId/rounds-leaderboard'} element={<RoundsLeaderboardPage/>}/>
					<Route path={'/:quizId/teams-leaderboard'} element={<LeaderboardTeamPage/>}/>
					<Route path={'/:quizId/team-players'} element={<TeamPlayersPage/>}/>
					
					<Route path={'/:quizId/rb/g/:round'} element={<RedBullGamePage/>}/>
					<Route path={'/:quizId/rb/l'} element={<RedBullLeaderboardPage/>}/>
					<Route path={'/rb/final'} element={<RedBullFinalLeaderboardPage/>}/>
					
					<Route path={'*'} element={<Message msg={'Error 404'}/>}/>
				</Routes>
			</BrowserRouter>
		</>
	)
}

export default App;
