import React from 'react';
import AnswerScreen from '../common-libs/quiz/screens/AnswerScreen';
import WithSound from '../common-libs/components/WithSound';
import PropTypes from 'prop-types';

export default function AnswerScreenWithSound(props) {
	
	const quiz = props.quiz;
	const question = props.question;
	
	const sound = quiz.screens.answer.sound;
	let soundUrl = null;
	if (sound && sound.length) {
		soundUrl = `${process.env.REACT_APP_S3_BUCKET}/sounds/${sound}.mp3`;
	}
	
	return <WithSound sound={soundUrl}>
		<AnswerScreen
			quiz={quiz}
			question={question}
		/>
	</WithSound>
}

AnswerScreenWithSound.propTypes = {
	quiz: PropTypes.object.isRequired,
	question: PropTypes.object.isRequired
}