const loaded = new Set();
const queue = [];

export default class ResourcePreloader {
	
	quiz;
	
	/**
	 * @param quiz
	 */
	constructor(quiz) {
		this.quiz = quiz;
		this.initialPreload();
		
		setInterval(this.loadNext, 1000);
	}
	
	/**
	 * Preload common quiz resources.
	 */
	initialPreload() {
		
		// Images.
		const media = [
			this.quiz.screens.question.background,
			this.quiz.screens.answer.background,
			this.quiz.screens.scores.success.background,
			this.quiz.screens.scores.fail.background
		];
		if (!this.quiz.loop) {
			if (this.quiz.screens.final.screenType === 'scores') {
				media.push(this.quiz.screens.final.scoresStyles.background);
			} else {
				media.push(this.quiz.screens.final.background);
			}
			
		}
		
		if (this.quiz.teams) {
			this.quiz.teams.forEach(team => media.push(team.img));
		}
		
		// Generate and array of sources.
		media.forEach(m => {
			if (m && m.mediaType === 'image') {
				this.preload(m.image.src, m.mediaType);
			}
		});
	}
	
	/**
	 * Preload resource.
	 *
	 * @param src
	 * @param type
	 */
	preload(src, type) {
		queue.push([src, type]);
	}
	
	loadNext() {
		if (!queue.length) {
			return;
		}
		
		const [src, type] = queue.shift();
		if (loaded.has(src)) {
			return;
		}
		loaded.add(src);
		
		if (type === 'image') {
			const preloadLink = document.createElement('link');
			preloadLink.href = src;
			preloadLink.rel = 'preload';
			preloadLink.as = type;
			document.head.appendChild(preloadLink);
			
		} else if (type === 'audio') {
			const audio = new Audio(src);
			audio.preload = 'auto';
		}
	}
}