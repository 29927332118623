import React from 'react';
import io from 'socket.io-client';
import WelcomeScreen from '../common-libs/quiz/screens/WelcomeScreen';
import QuestionScreenWithSound from './QuestionScreenWithSound';
import ScoresScreenWithSound from './ScoresScreenWithSound';
import ResourcePreloader from '../services/resource-preloader';
import FinalGameScreen from './FinalGameScreen';
import AnswerScreenWithSound from './AnswerScreenWithSound';
import Poster from './Poster';
import WatermarkContext from '../common-libs/context/WatermarkContext';
import ScoresScreenTeam from '../common-libs/quiz/screens/ScoresScreenTeam';

export default class Game extends React.Component {
	
	client;
	preloader;
	
	/**
	 * @param props
	 * @param context
	 */
	constructor(props, context) {
		super(props, context);
		this.state = {
			quiz: props.quiz
		};
	}
	
	/**
	 * Connect to the quiz server.
	 */
	componentDidMount() {
		this.client = io(`${this.state.quiz.serverUrl}/?quizId=${this.state.quiz._id}`, {
			transports: ['websocket']
		});
		this.client.on('cmd', this.onMessage.bind(this));
		this.client.on('stop', () => window.location.reload());
		
		this.preloader = new ResourcePreloader(this.state.quiz);
	}
	
	/**
	 * Cleanup.
	 */
	componentWillUnmount() {
		this.client.disconnect();
	}
	
	/**
	 * @param msg
	 */
	onMessage(msg) {
		
		// Check if quiz is running or not.
		const quiz = {
			...this.state.quiz,
			status: msg.running ? 'running' : 'paused',
			numQuestionsAsked: msg.numQuestionsAsked,
			totalQuestions: msg.totalQuestions,
			scoreScreenType: msg.scoreScreenType || 'user'
		};
		const newState = {quiz};
		
		if (msg.action === 'screen') {
			
			newState.screen = msg.screen;
			newState.date = msg.date;
			
			if (msg.till) {
				newState.till = msg.till;
			}
			
			if (['question', 'answer'].includes(msg.screen)) {
				newState.question = msg.question;
			} else if (['scores', 'final'].includes(msg.screen)) {
				newState.scores = msg.results || [];
				newState.teamScores = msg.teamResults || {};
			}
		}
		this.setState(newState);
		
		if (msg.preload && msg.preload.length) {
			msg.preload.forEach(src => this.preloader.preload(src, 'image'));
		}
	}
	
	/**
	 * Render.
	 *
	 * @returns {JSX.Element}
	 */
	render() {
		const quiz = this.state.quiz;
		const screen = this.state.screen;
		const screenType = quiz.scoreScreenType;
		const scores = screenType === 'team' ? this.state.teamScores : this.state.scores;
		
		return !this.state.screen ?
			<Poster quiz={quiz}/> :
			<WatermarkContext.Provider value={false}>
				{screen === 'welcome' && <WelcomeScreen
					quiz={quiz}
					timeLeft={this.state.till - this.state.date}
				/>}
				
				{screen === 'question' && <QuestionScreenWithSound
					quiz={quiz}
					timeLeft={this.state.till - this.state.date}
					question={this.state.question}
				/>}
				
				{screen === 'answer' && <AnswerScreenWithSound
					quiz={quiz}
					question={this.state.question}
				/>}
				
				{(screen === 'scores' && screenType === 'team') && <ScoresScreenTeam
					teams={quiz.teams}
					scores={scores}
					title={quiz.screens.scores.title}
					styles={{
						title: quiz.screens.scores.screenTitle,
						page: quiz.screens.scores.success
					}}
				/>}
				
				{(screen === 'scores' && screenType !== 'team') && <ScoresScreenWithSound
					quiz={quiz}
					scores={scores}
				/>}
				
				{screen === 'final' && <FinalGameScreen
					quiz={quiz}
					scores={this.state.scores}/>
				}
			</WatermarkContext.Provider>
	}
}
