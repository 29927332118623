import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';
import Message from '../components/Message';
import ScoresScreen from '../common-libs/quiz/screens/ScoresScreen';
import {populateTeams} from '../common-libs/quiz/utils/QuizUtils';
import objectPath from 'object-path';

function RoundsLeaderboardPage() {
	
	const {quizId} = useParams();
	const [query] = useSearchParams();
	const rounds = query.get('rounds');
	const cssClass = query.get('cssClass') || '';
	
	const [state, setState] = useState({message: null});
	
	
	/**
	 * Load the scores.
	 */
	useEffect(() => {
		let active = true;
		
		if (!rounds) {
			return setState({message: 'Round IDs are required'});
		}
		
		const roundIds = rounds.split(',');
		if (!roundIds.length) {
			return setState({message: 'Invalid Round IDs'});
		}
		
		(async () => {
			try {
				const url = new URL(`${process.env.REACT_APP_API_SERVER}/${quizId}/rounds-leaderboard`);
				url.searchParams.append('roundIds', roundIds);
				url.searchParams.append('limit', query.get('limit'));
				
				const response = await fetch(url);
				const quiz = await response.json();
				
				if (!active) {
					return;
				} else if (quiz.error) {
					return setState({message: quiz.error});
				}
				
				const scores = populateTeams(objectPath.get(quiz, 'leaderboard.topUsers'), quiz.teams);
				setState({quiz, scores});
				
			} catch (e) {
				console.log(e);
				return setState({message: 'Something went wrong. Please try again later.'})
			}
		})();
		
		return () => {
			active = false;
		}
		
	}, [quizId, query, rounds]);
	
	return (
		<div className={`rounds-leaderboard-page ${cssClass}`}>
			{state.message && <Message msg={state.message}/>}
			{(state.quiz && state.quiz.customCss) && <style>{state.quiz.customCss}</style>}
			{state.quiz && <ScoresScreen
				autoScroll={!query.get('disableScroll')}
				scores={state.scores}
				title={state.quiz.screens.scores.title}
				showPos={state.quiz.screens.scores.success.showPos}
				showTeam={state.quiz.screens.scores.success.showTeam}
				showNewScores={false}
				scrollDelay={state.quiz.screens.scores.success.scrollDelay}
				styles={{
					title: state.quiz.screens.scores.screenTitle,
					page: state.quiz.screens.scores.success
				}}
			/>}
		</div>
	);
}

export default RoundsLeaderboardPage;
